export const initNavbarToggler = () => {
  const navbarToggler =
    document.querySelector<HTMLElement>(".js-navbar-toggler");
  const headerNav = document.querySelector<HTMLElement>(".js-navigation");

  navbarToggler?.addEventListener("click", () => {
    headerNav?.classList.toggle("active");
    navbarToggler.classList.toggle("active");

    document.documentElement.classList.toggle("no-scroll");
  });
};

export const initGuestHouseNavbarToggler = () => {
  const navbarTogglerEl = document.querySelector<HTMLElement>(
    ".js-guesthouse-navigation-toggler",
  );

  const navbarEl = document.querySelector<HTMLElement>(
    ".js-guesthouse-navigation",
  );

  const contactTogglerEl = document.querySelector<HTMLElement>(
    ".js-guesthouse-contact-toggler",
  );

  const contactEl = document.querySelector<HTMLElement>(
    ".js-guesthouse-contact",
  );

  navbarTogglerEl?.addEventListener("click", (e) => {
    navbarTogglerEl.classList.toggle("active");
    navbarEl?.classList.toggle("open");
    e.stopPropagation();
  });

  contactTogglerEl?.addEventListener("click", (e) => {
    contactTogglerEl.classList.toggle("active");
    contactEl?.classList.toggle("open");
    e.stopPropagation();
  });

  document.addEventListener("click", () => {
    contactEl?.classList.remove("open");
    contactTogglerEl?.classList.remove("active");
    navbarEl?.classList.remove("open");
    navbarTogglerEl?.classList.remove("active");
  });

  const observer = new IntersectionObserver(changeNav, {
    threshold: 1,
  });
  const sections = document.querySelectorAll(".js-section-guesthouse");
  sections.forEach((section) => {
    observer.observe(section);
  });
};

const changeNav = (entries: IntersectionObserverEntry[]) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      document.querySelector(".active")?.classList.remove("active");
      const id = entry.target.getAttribute("id");
      document.querySelector(`[href="#${id}"]`)?.classList.add("active");
    }
  });
};
