export const initVideo = () => {
  const allYouTubeVideoContainers = document.querySelectorAll<HTMLElement>(
    ".js-video-container",
  );

  const introVideoContainer = document.querySelector<HTMLElement>(
    ".js-home-intro-image-slider",
  );

  [...allYouTubeVideoContainers].map((el) => {
    el.hidden = false;
    if (introVideoContainer) {
      introVideoContainer.hidden = true;
    }
    const iframe = el.querySelector<HTMLIFrameElement>(".js-video-iframe");
    const src = iframe?.dataset["src"];

    if (src) iframe.src = src;

    el.classList.add("active");
  });
};
