import { RefObject, useEffect } from "react";

type Event = MouseEvent | TouchEvent;

const useOnClick = <T extends HTMLElement | null>(
  ref: RefObject<T>,
  inside: (e: Event) => void,
  outside: (e: Event) => void,
) => {
  useEffect(() => {
    const listener = (e: Event) => {
      if (!(e.target instanceof Node) || ref.current?.contains(e.target)) {
        inside(e);
        return;
      }
      outside(e);
    };

    document.addEventListener("mouseup", listener);
    document.addEventListener("touchend", listener);

    return () => {
      document.removeEventListener("mouseup", listener);
      document.removeEventListener("touchend", listener);
    };
  }, [ref, inside, outside]);
};

export default useOnClick;
