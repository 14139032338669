import { createRoot } from "react-dom/client";
import Search from "./components/Search/Search";
import { Region } from "./http/api";

export const initSearch = () => {
  const headerElement =
    document.querySelector<HTMLElement>(".js-header-search");
  const mainElement = document.querySelector<HTMLElement>(".js-search");
  const element = mainElement ?? headerElement;
  if (!element) return;

  const action = element.dataset["action"];
  if (!action) return;

  const regions: Record<string, string> = headerElement?.dataset["regions"]
    ? JSON.parse(headerElement.dataset["regions"])
    : {};

  const translatedRegions: Region[] = Object.entries(regions).map(
    ([id, name]) => ({
      id,
      name,
    }),
  );

  const root = createRoot(element);
  root.render(
    <Search
      action={action}
      isHeader={!mainElement}
      translatedRegions={translatedRegions}
    />,
  );
};
