import { useState } from "react";

const useToggle = (inital?: boolean) => {
  const [isOpen, setIsOpen] = useState(inital ?? false);

  return {
    isOpen,
    close: () => setIsOpen(false),
    open: () => setIsOpen(true),
    toggle: () => setIsOpen((isOpen) => !isOpen),
  };
};

export default useToggle;
