import { useEffect, useRef, useState } from "react";

const useIsFocused = <RefElement extends HTMLElement>(
  initialIsFocused: boolean,
) => {
  const ref = useRef<RefElement>(null);
  const [isFocused, setFocused] = useState(initialIsFocused);

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const target = e.target as Element | null;

      if (!ref.current?.contains(target)) {
        setFocused(false);
      }
    };

    document.addEventListener("click", onClick, true);
    return () => {
      document.removeEventListener("click", onClick);
    };
  }, []);

  return { ref, isFocused, setFocused };
};

export default useIsFocused;
