import useSWRImmutable from "swr/immutable";
import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";
import { currentLanguage } from "../i18n";

interface FetcherOptions {
  url: string;
  query?: object;
  signal?: AbortSignal;
}

export type ItemType = "REGION" | "PLACE" | "GUESTHOUSE";

export interface Item {
  name: string;
  key: string;
  type: ItemType;
}

export interface Region {
  id: string;
  name: string;
}

const api = wretch().addon(QueryStringAddon).url(`/${currentLanguage}/api`);

const fetcher = <Response>({ url, query, signal }: FetcherOptions) =>
  api
    .options(signal ? { signal } : {})
    .url(url)
    .query(query ?? {})
    .get()
    .json<Response>();

export const useSearch = ({ q }: { q: string }) =>
  useSWRImmutable({ url: "/search", query: { q } }, (opts) =>
    fetcher<{ items: Item[] }>(opts),
  );
