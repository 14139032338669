import jsonTranslations from "../languages.json";

const common = jsonTranslations.de;

const de = {
  searchBar: common.SEARCH_BAR,
  regions: {
    BOZEN_UMGEBUNG: common.REGIONS.BOZEN_UMGEBUNG,
    EISACKTAL: common.REGIONS.EISACKTAL,
    WIPPTAL: common.REGIONS.WIPPTAL,
    PUSTERTAL_GADERTAL: common.REGIONS.PUSTERTAL_GADERTAL,
    MERAN_UMGEBUNG: common.REGIONS.MERAN_UMGEBUNG,
    VINSCHGAU: common.REGIONS.VINSCHGAU,
  },
};

export default de;

export type DefaultI18n = typeof de;
