import classNames from "classnames";
import { formatDate } from "date-fns";
import { useEffect, useRef, useState } from "react";
import "react-day-picker/dist/style.css";
import CloseMap from "../../../img/close-map.svg?react";
import Magnifier from "../../../img/magnifier.svg?react";
import STG from "../../../img/stg-logo.svg?react";
import { RegionName } from "../../@types";
import { Region } from "../../http/api";
import i18n from "../../i18n";
import useMatchesMedia from "../hooks/useMatchesMedia";
import useOnClick from "../hooks/useOnClick";
import useToggle from "../hooks/useToggle";
import Icon from "../ui/Icon";
import DateField from "./DateField";
import RegionField from "./RegionField";
import styles from "./Search.module.css";
import SearchField from "./SearchField";

interface Props {
  action: string;
  isHeader: boolean;
  translatedRegions: Region[];
}

interface StateType {
  q: string | undefined;
  date: Date | undefined;
  regions: Region[];
  mapOpen: boolean;
}

const getInitialState = (translatedRegions: Region[]): StateType => {
  const urlSearchParams = new URLSearchParams(location.search);

  const dateParam = urlSearchParams.get("date");
  const dateFormat = dateParam && new Date(dateParam);

  const regionsParam = urlSearchParams.get("regions");
  const regionsArray = regionsParam?.split(",");

  return {
    q: urlSearchParams.get("q") ?? undefined,
    date: dateFormat && !isNaN(dateFormat.getTime()) ? dateFormat : undefined,
    regions: translatedRegions.filter(({ id }) => regionsArray?.includes(id)),
    mapOpen: Boolean(Number(urlSearchParams.get("map_open"))),
  };
};

const getSearchString = (state: StateType) => {
  const searchString = [
    state.q,
    state.date ? formatDate(state.date, "dd.MM.yyyy") : undefined,
    state.regions.length
      ? state.regions.map((r) => i18n.regions[r.name as RegionName]).join(", ")
      : undefined,
  ]
    .filter((a) => a)
    .join(", ");

  return searchString.length ? searchString : i18n.searchBar.FIND_GUESTHOUSE;
};

const Search = ({ action, isHeader, translatedRegions }: Props) => {
  const [state, setState] = useState<StateType>(
    getInitialState(translatedRegions),
  );
  const matchesMedia = useMatchesMedia("(min-width: 1024px)");
  const mobileOverlay = useToggle(matchesMedia);
  const isMobile = !matchesMedia;
  const overlay = useToggle(false);

  const ref = useRef<HTMLFormElement>(null);
  useOnClick(ref, overlay.open, overlay.close);

  useEffect(() => {
    if (mobileOverlay.isOpen === matchesMedia) return;
    matchesMedia ? mobileOverlay.open() : mobileOverlay.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchesMedia]);

  useEffect(() => {
    document.documentElement.classList.toggle(
      "no-scroll",
      !matchesMedia && mobileOverlay.isOpen,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileOverlay.isOpen]);

  useEffect(() => {
    const navbarToggler = document.querySelector(".js-navbar-toggler");
    navbarToggler?.classList.toggle("overlay-open", overlay.isOpen);
  }, [overlay.isOpen]);

  return (
    <>
      {isMobile && (
        <div className={styles.nav}>
          <button
            type="button"
            className={styles.searchBtn}
            onClick={mobileOverlay.open}
          >
            <Icon glyph={Magnifier} className={styles.magnifierIcon} />
            <p className={styles.searchText}>{getSearchString(state)}</p>
          </button>
        </div>
      )}
      {mobileOverlay.isOpen && (
        <div
          className={classNames(
            { [styles.overlay]: overlay.isOpen && isHeader },
            { [styles.mobileOverlay]: isMobile },
          )}
        >
          {isMobile && (
            <>
              <div className={styles.header}>
                <a href="/">
                  <Icon glyph={STG} className={styles.headerLogoIcon} />
                </a>
                <button
                  type="button"
                  onClick={mobileOverlay.close}
                  className={styles.headerCloseBtn}
                >
                  <Icon glyph={CloseMap} className={styles.headerCloseIcon} />
                </button>
              </div>
              <h5 className={styles.searchBarTitle}>{i18n.searchBar.TITLE}</h5>
            </>
          )}
          <form
            ref={ref}
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
              overlay.close();
              const url = new URL(action, location.origin);
              state.q && url.searchParams.set("q", state.q);
              state.date &&
                url.searchParams.set(
                  "date",
                  formatDate(state.date, "yyyy-MM-dd"),
                );
              state.regions.length &&
                url.searchParams.set(
                  "regions",
                  state.regions.map(({ id }) => id).join(","),
                );
              state.mapOpen && url.searchParams.set("map_open", "1");
              location.href = url.toString();
            }}
          >
            {matchesMedia && (
              <Icon glyph={Magnifier} className={styles.magnifierIcon} />
            )}
            <SearchField
              inputValue={state.q ?? ""}
              onUpdate={(query) => setState({ ...state, q: query })}
              setAutoFocus={isMobile}
            />
            <DateField
              inputValue={state.date}
              onUpdate={(date) => setState({ ...state, date: date })}
            />
            <RegionField
              regions={translatedRegions}
              selectedItems={state.regions}
              onUpdate={(regions) => setState({ ...state, regions: regions })}
            />
            <button
              type="submit"
              className={classNames(styles.submitBtnDesktop, styles.submitBtn)}
            >
              {i18n.searchBar.SEARCH}
            </button>
            {isMobile && (
              <div className={styles.searchFooter}>
                <button
                  type="button"
                  className={styles.deleteBtn}
                  onClick={() =>
                    setState({
                      q: undefined,
                      date: undefined,
                      regions: [],
                      mapOpen: false,
                    })
                  }
                >
                  {i18n.searchBar.DELETE_ALL}
                </button>
                <button
                  type="submit"
                  className={classNames(
                    styles.submitBtnMobile,
                    styles.submitBtn,
                  )}
                >
                  {i18n.searchBar.SEARCH}
                </button>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default Search;
