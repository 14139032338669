import { Loader } from "@googlemaps/js-api-loader";
import { renderToStaticMarkup } from "react-dom/server";

interface Guesthouse {
  title: string;
  link: string;
  city: string;
  latitude: number;
  longitude: number;
  image: string;
}

const getBoundsCenter = (guesthouses: Guesthouse[]) => {
  const bounds = new google.maps.LatLngBounds();
  guesthouses.forEach((guesthouses) => {
    bounds.extend(
      new google.maps.LatLng(guesthouses.latitude, guesthouses.longitude),
    );
  });
  return bounds.getCenter();
};

const initMap = async (mapElement: HTMLElement) => {
  const apiKey = mapElement.dataset["googleMapsApiKey"];
  if (!apiKey) return;

  const loader = new Loader({ apiKey });

  const { Map, InfoWindow } = await loader.importLibrary("maps");
  const { AdvancedMarkerElement, PinElement } =
    await loader.importLibrary("marker");

  const guesthouses: Guesthouse[] = JSON.parse(
    mapElement.dataset["guesthouses"] ?? "",
  );

  const map = new Map(mapElement, {
    center: getBoundsCenter(guesthouses),
    zoom: 10,
    mapId: mapElement.dataset["googleMapsMapId"] ?? null,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    clickableIcons: false,
  });

  let openedPin: google.maps.marker.PinElement | null = null;
  let openedInfoWindow: google.maps.InfoWindow | null = null;

  const documentElement = window.getComputedStyle(document.documentElement);
  const color = documentElement.getPropertyValue("--color-pink-69");
  const colorActive = documentElement.getPropertyValue("--color-pink-58");
  const backgroundWhite =
    documentElement.getPropertyValue("--background-color");

  guesthouses.forEach((item) => {
    const pin = new PinElement({
      background: color,
      borderColor: color,
      glyphColor: backgroundWhite,
    });

    const marker = new AdvancedMarkerElement({
      map,
      position: { lat: item.latitude, lng: item.longitude },
      content: pin.element,
    });

    if (mapElement.classList.contains("js-map")) return;

    const infowindow = new InfoWindow({
      content: renderToStaticMarkup(
        <a href={item.link} className="guesthouse-window">
          <img src={item.image} width="280px" height="250px" alt={item.title} />
          <div className="window-content">
            <h4 className="guesthouse-window-title">{item.title}</h4>
            <p className="guesthouse-window-city">{item.city}</p>
          </div>
        </a>,
      ),
    });

    marker.addListener("click", () => {
      openedInfoWindow?.close();
      if (openedPin) {
        openedPin.background = color;
        openedPin.borderColor = color;
      }

      infowindow.open({
        anchor: marker,
        map,
      });
      pin.background = colorActive;
      pin.borderColor = colorActive;

      openedInfoWindow = infowindow;
      openedPin = pin;
    });

    google.maps.event.addListener(map, "click", () => {
      pin.background = color;
      pin.borderColor = color;
      infowindow.close();
    });
  });
};

export const handleMapLoad = () => {
  const mapElement = document.querySelector<HTMLElement>(".js-map");
  const mapSearchElement =
    document.querySelector<HTMLElement>(".js-search-map");

  mapElement && initMap(mapElement);
  mapSearchElement && initMap(mapSearchElement);
};
