import "../css/entry.css";
import { initCarousels } from "./components/carousel";
import { initLightbox } from "./components/lightbox";

import "./components/map";
import {
  initGuestHouseNavbarToggler,
  initNavbarToggler,
} from "./components/navbar-toggler";
import { initPopup } from "./components/popup";
import { initScroll } from "./components/scroll";
import { handleSelect } from "./components/select";
import {
  addUsercentricsCloseEventListener,
  handleUsercentricsConsents,
  initUsercentricsButtons,
} from "./components/usercentrics";
import registerErrorReporting from "./error-reporting";
import { initSearch } from "./initSearch";

registerErrorReporting();
initUsercentricsButtons();
initNavbarToggler();
initLightbox();
initScroll();
handleSelect();
initCarousels();
initGuestHouseNavbarToggler();
initSearch();

addUsercentricsCloseEventListener(initPopup);
handleUsercentricsConsents();
