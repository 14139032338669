/* eslint-disable jsx-a11y/role-supports-aria-props */
import classNames from "classnames";
import { formatDate } from "date-fns";
import { useId } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import CloseMap from "../../../img/close-map.svg?react";
import i18n, { currentLocale } from "../../i18n";
import useIsFocused from "../hooks/useIsFocused";
import useMatchesMedia from "../hooks/useMatchesMedia";
import Icon from "../ui/Icon";
import "./DateField.css";
import styles from "./Search.module.css";

interface Props {
  inputValue: Date | undefined;
  onUpdate: (query: Date | undefined) => void;
}

const DateField = ({ inputValue, onUpdate }: Props) => {
  const matchesMedia = useMatchesMedia("(min-width: 768px)");
  const { ref, isFocused, setFocused } = useIsFocused<HTMLDivElement>(false);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const inputId = useId();

  return (
    <div ref={ref} className={styles.inputContainer}>
      <label htmlFor={inputId} className={styles.fieldLabel}>
        {i18n.searchBar.DATE}
      </label>
      <div className={classNames(styles.inputField, styles.inputFieldPipe)}>
        <input
          readOnly={true}
          value={
            inputValue
              ? formatDate(inputValue, "EEEEEE, dd. LLL", {
                  locale: currentLocale,
                })
              : ""
          }
          onClick={() => setFocused(!isFocused)}
          className={styles.input}
          placeholder={i18n.searchBar.DATE}
          aria-expanded={isFocused}
          id={inputId}
        />
        {inputValue && isFocused && (
          <button
            type="button"
            className={styles.closeBtn}
            onClick={() => onUpdate(undefined)}
          >
            <Icon glyph={CloseMap} className={styles.closeIcon} />
          </button>
        )}
      </div>
      <div className={styles.dateOverlay}>
        {isFocused && (
          <div className={styles.searchMenuOpen}>
            <DayPicker
              mode="single"
              locale={currentLocale}
              selected={inputValue}
              disabled={{ before: today }}
              startMonth={today}
              numberOfMonths={matchesMedia ? 2 : 1}
              {...(inputValue && { defaultMonth: inputValue })}
              onDayClick={(date) => {
                onUpdate(date);
                setFocused(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DateField;
