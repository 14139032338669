import EmblaCarousel, {
  EmblaCarouselType,
  EmblaEventType,
  EmblaOptionsType,
} from "embla-carousel";

const generateDotBtns = (
  dotsContainer: HTMLElement,
  carousel: EmblaCarouselType,
) => {
  const scrollSnaps = carousel.scrollSnapList();
  const dotsFrag = document.createDocumentFragment();
  const dotsArray = scrollSnaps.map(() => document.createElement("span"));
  dotsArray.forEach((dotNode, i) => {
    dotsFrag.appendChild(dotNode);

    dotNode.classList.add("slider__dot");
    !i && dotNode.classList.add("is-selected");
    dotNode.addEventListener("click", () => carousel.scrollTo(i), false);
  });
  dotsContainer.appendChild(dotsFrag);
  return dotsArray;
};

const selectDotBtn =
  (dotsArray: HTMLSpanElement[], carousel: EmblaCarouselType) => () => {
    const selected = carousel.selectedScrollSnap();
    const previous = selected - 1;
    const next = selected + 1;

    dotsArray[previous]?.classList.add("scale-medium");
    dotsArray[next]?.classList.add("scale-medium");
    dotsArray[previous - 1]?.classList.remove("scale-medium");
    dotsArray[next + 1]?.classList.remove("scale-medium");

    dotsArray.forEach((dot, index) => {
      dot.classList.toggle("is-selected", index === selected);
    });
  };

const handleDots = (
  carousel: EmblaCarouselType,
  dotsContainer: HTMLElement,
) => {
  const dotsArray = generateDotBtns(dotsContainer, carousel);
  const setSelectedDotBtn = selectDotBtn(dotsArray, carousel);

  carousel.on("select", setSelectedDotBtn);

  carousel.on("select", () => {
    const currentIndex = carousel.selectedScrollSnap();
    dotsContainer.style.transform = `translateX(${
      currentIndex >= 3 && dotsArray.length > 4 ? -((currentIndex - 2) * 16) : 0
    }px)`;
  });
};

const handleNavigation = (
  carousel: EmblaCarouselType,
  leftArrowElement: HTMLButtonElement | null,
  rightArrowElement: HTMLButtonElement | null,
) => {
  if (!leftArrowElement || !rightArrowElement) return;

  leftArrowElement.disabled = !carousel.canScrollPrev();
  rightArrowElement.disabled = !carousel.canScrollNext();
};

const addDotCarousel = (
  viewportNode: HTMLElement,
  options?: Partial<EmblaOptionsType>,
) => {
  const carousel = EmblaCarousel(viewportNode, {
    align: "start",
    ...options,
  });

  const toggleEmblaReady = (
    _emblaApi: EmblaCarouselType,
    event: EmblaEventType,
  ) => {
    const isResizeEvent = event === "resize";
    isResizeEvent && carousel.reInit(options);
  };

  carousel.on("init", toggleEmblaReady);
  carousel.on("resize", toggleEmblaReady);
  carousel.on("reInit", toggleEmblaReady);

  carousel.on("resize", () => {
    const slideEl = carousel.slideNodes()[carousel.selectedScrollSnap()];
    slideEl?.classList.add("is-selected");
  });

  const dotsContainer = viewportNode.querySelector<HTMLElement>(
    ":scope > .js-slider__dots > .js-dot-image-slider__dots",
  );
  dotsContainer && handleDots(carousel, dotsContainer);
};

const addArrowCarousel = (
  emblaNode: HTMLElement,
  viewportNode: HTMLElement,
  options?: Partial<EmblaOptionsType>,
) => {
  const carousel = EmblaCarousel(viewportNode, {
    align: "start",
    containScroll: "trimSnaps",
    ...options,
  });

  const leftArrowElement =
    emblaNode.querySelector<HTMLButtonElement>(".js-carousel-prev");
  const rightArrowElement =
    emblaNode.querySelector<HTMLButtonElement>(".js-carousel-next");

  leftArrowElement?.addEventListener("click", () => carousel.scrollPrev());
  rightArrowElement?.addEventListener("click", () => carousel.scrollNext());

  if (options?.loop) return;

  carousel.on("select", () =>
    handleNavigation(carousel, leftArrowElement, rightArrowElement),
  );
  carousel.on("init", () =>
    handleNavigation(carousel, leftArrowElement, rightArrowElement),
  );
};

export const initCarousels = () => {
  const dotSliders = document.querySelectorAll<HTMLElement>(
    ".js-dot-and-arrow-slider",
  );

  dotSliders.forEach((dotSlider) => {
    const viewportNode =
      dotSlider.querySelector<HTMLElement>(".embla__viewport");
    const data = dotSlider?.dataset["carouselType"];
    data && dotSlider.classList.add(data);
    const isOnlyDots = data === "onlyDots";
    viewportNode &&
      addDotCarousel(viewportNode, {
        align: isOnlyDots ? "center" : "start",
        skipSnaps: false,
        watchDrag: true,
        active: true,
        breakpoints: {
          "(min-width: 769px)": { active: isOnlyDots },
          "(min-width: 1024px)": { active: false },
        },
      });

    viewportNode &&
      addArrowCarousel(dotSlider, viewportNode, {
        active: false,
        watchDrag: false,
        breakpoints: {
          "(min-width: 769px)": { active: true },
        },
      });
  });

  const dotSliderEls = document.querySelectorAll<HTMLElement>(
    ".js-dot-image-slider",
  );
  dotSliderEls.forEach((dotSlider) => {
    const viewportNode =
      dotSlider.querySelector<HTMLElement>(".embla__viewport");
    viewportNode &&
      addDotCarousel(viewportNode, {
        skipSnaps: false,
        watchDrag:
          dotSlider?.dataset["carouselType"] === "alwaysOn" ? true : false,
        breakpoints: {
          "(min-width: 769px)": {
            watchDrag:
              dotSlider?.dataset["carouselType"] === "onlyDots" ? false : true,
          },
          "(min-width: 1024px)": { watchDrag: true },
        },
      });
  });
};
