export const handleSelect = () => {
  const searchParams = new URLSearchParams(location.search);

  const changeParams = (e: Event, key: string, hash: string) => {
    const target = <HTMLSelectElement>e.target;

    !target.value
      ? searchParams.delete(key)
      : searchParams.set(key, target.value);

    location.hash = hash;
    location.search = searchParams.toString();
  };

  const regionSelection = document.querySelector<HTMLSelectElement>(
    ".js-guesthouse-select-region",
  );
  regionSelection?.addEventListener("change", (e) => {
    changeParams(e, "region", "guesthouses");
  });

  const courtSelection =
    document.querySelector<HTMLSelectElement>(".js-select-court");
  courtSelection?.addEventListener("change", (e) => {
    changeParams(e, "court", "recipes");
  });
};
