export const initScroll = () => {
  const triggerElement = document.querySelector<HTMLElement>(
    ".js-header-observer-trigger",
  );
  const pageHeader = document.querySelector<HTMLElement>(".js-main-header");
  const className = "scroll";
  if (!triggerElement || !pageHeader) return;

  const observer = new IntersectionObserver(([entry]) => {
    if (entry?.intersectionRatio === 1) {
      pageHeader.classList.remove(className);
      return;
    }
    pageHeader.classList.toggle(className);
  });
  observer.observe(triggerElement);
};
