import { ConsentDetails, Usercentrics } from "usercentrics-browser-ui";
import { handleMapLoad } from "./map";
import { initVideo } from "./video";

interface UsercentricsWithCmpController extends Usercentrics {
  cmpController: { ui: { initialView: "none" | "first" | "second" } };
}

type UsercentricsViewChangeEvent = CustomEvent<{
  view: "NONE" | "FIRST_LAYER" | "SECOND_LAYER";
}>;

type UsercentricsConsentEvent = CustomEvent<ConsentDetails>;

interface UsercentricsConsents {
  googleMaps: boolean | undefined;
  youTube: boolean | undefined;
}

export const initUsercentricsButtons = () => {
  document.addEventListener("click", (e) => {
    if (!(e.target instanceof Element)) return;
    const btn = e.target.closest('[href="#show-usercentrics-modal"]');

    if (!btn) return;

    e.preventDefault();
    window.__ucCmp.showSecondLayer();
  });
};

const getIsUsercentricsViewChangeEvent = (
  event: Event,
): event is UsercentricsViewChangeEvent => event.type === "UC_UI_VIEW_CHANGED";

const getIsUsercentricsConsentEvent = (
  event: Event,
): event is UsercentricsConsentEvent => event.type === "UC_CONSENT";

const addUsercentricsConsentEventListener = async (
  callback: (consents: UsercentricsConsents) => void,
) => {
  if (window.__ucCmp) {
    const consentDetails = await window.__ucCmp.getConsentDetails();
    callback(getUsercentricsConsents(consentDetails));
  }

  window.addEventListener(
    "UC_CONSENT",
    (event) =>
      getIsUsercentricsConsentEvent(event) &&
      callback(getUsercentricsConsents(event.detail)),
  );
};

export const handleUsercentricsConsents = () => {
  let isMapsConsented = false;
  let isYouTubeVideoConsented = false;

  addUsercentricsConsentEventListener((consents) => {
    if (isMapsConsented || consents.googleMaps) {
      const allAlternativeTexts =
        document.querySelectorAll<HTMLElement>(".map-alternative");

      for (const el of allAlternativeTexts) {
        el.hidden = true;
      }
      handleMapLoad();
      isMapsConsented = true;
    }

    if (consents.youTube || isYouTubeVideoConsented) {
      initVideo();
      isYouTubeVideoConsented = true;
    }
  });
};

export const addUsercentricsCloseEventListener = (callback: () => void) => {
  const onViewChanged = (event: Event) => {
    if (
      !getIsUsercentricsViewChangeEvent(event) ||
      event.detail.view !== "NONE"
    ) {
      return;
    }

    window.removeEventListener("UC_UI_VIEW_CHANGED", onViewChanged);
    callback();
  };

  window.addEventListener(
    "UC_UI_INITIALIZED",
    () => {
      const initialView = (window.__ucCmp as UsercentricsWithCmpController)
        ?.cmpController.ui.initialView;
      if (initialView === "none") {
        callback();
        return;
      }
      window.addEventListener("UC_UI_VIEW_CHANGED", onViewChanged);
    },
    { once: true },
  );
};

const getUsercentricsConsents = ({
  services,
}: ConsentDetails): UsercentricsConsents => {
  const mapping = {
    "YouTube Video": "youTube",
    "Google Maps": "googleMaps",
  } as const;

  const isName = (key: string): key is keyof typeof mapping =>
    Object.keys(mapping).includes(key);

  return Object.values(services).reduce<UsercentricsConsents>(
    (acc, curr) =>
      isName(curr.name)
        ? {
            ...acc,
            [mapping[curr.name]]: curr.consent?.given,
          }
        : acc,
    { googleMaps: undefined, youTube: undefined },
  );
};
