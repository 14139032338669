import classNames from "classnames";
import { useCombobox } from "downshift";
import { useId } from "react";
import { Fragment } from "react/jsx-runtime";
import CloseMap from "../../../img/close-map.svg?react";
import { Item, ItemType, useSearch } from "../../http/api";
import i18n from "../../i18n";
import useIsFocused from "../hooks/useIsFocused";
import Icon from "../ui/Icon";
import styles from "./Search.module.css";

interface Props {
  inputValue: string;
  onUpdate: (query: string) => void;
  setAutoFocus: boolean;
}

const SearchField = ({ inputValue, onUpdate, setAutoFocus }: Props) => {
  const { data, isLoading } = useSearch({
    q: inputValue,
  });
  let category: ItemType;
  const { ref, isFocused, setFocused } = useIsFocused<HTMLDivElement>(false);
  const inputId = useId();

  const {
    isOpen,
    getInputProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox<Item>({
    items: data?.items ?? [],
    inputValue,
    itemToString: (item) => item?.name ?? "",
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          onUpdate(inputValue ?? "");
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          selectedItem && onUpdate(selectedItem.name);
          break;
        default:
          break;
      }
    },
  });

  return (
    <div ref={ref} className={styles.inputContainer}>
      <label htmlFor={inputId} className={styles.fieldLabel}>
        {i18n.searchBar.FIND_GUESTHOUSE}
      </label>
      <div className={classNames(styles.inputField, styles.inputFieldPipe)}>
        <input
          {...getInputProps({
            onClick: () => setFocused(true),
          })}
          className={styles.input}
          placeholder={i18n.searchBar.FIND_GUESTHOUSE}
          id={inputId}
          // Autofocus is set after an interaction therefore ESLint the rule can be ignored
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={setAutoFocus}
        />
        {!!inputValue.length && isFocused && (
          <button
            type="button"
            className={styles.closeBtn}
            onClick={() => onUpdate("")}
          >
            <Icon glyph={CloseMap} className={styles.closeIcon} />
          </button>
        )}
      </div>
      <div className={styles.searchOverlay} {...getMenuProps()}>
        {isOpen && (
          <>
            {!!data?.items.length && (
              <ul className={styles.searchMenuOpen}>
                {data?.items.map((item, index) => {
                  const isNewCategory = item.type !== category;
                  if (isNewCategory) category = item.type;

                  return (
                    <Fragment key={index}>
                      {isNewCategory && (
                        <li className={styles.itemCategory}>
                          {i18n.searchBar[item.type.toUpperCase() as ItemType]}
                        </li>
                      )}
                      <li
                        {...getItemProps?.({
                          item,
                          index,
                        })}
                        className={classNames(styles.item, {
                          [styles.itemActive]: highlightedIndex === index,
                        })}
                      >
                        {item.name}
                      </li>
                    </Fragment>
                  );
                })}
              </ul>
            )}
            {!isLoading && !data?.items.length && inputValue.length > 1 && (
              <div className={styles.notItems}>{i18n.searchBar.NO_RESULTS}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchField;
