import { Locale } from "date-fns";
import { de as dateDE, enGB as dateEN, it as dateIT } from "date-fns/locale";
import { Language } from "../@types";
import de from "./de";
import en from "./en";
import it from "./it";

export const currentLanguage = <Language>document.documentElement.lang;

const dateLocales: { [lang in Language]: Locale } = {
  de: dateDE,
  it: dateIT,
  en: dateEN,
};

export const currentLocale = dateLocales[currentLanguage];

const i18n = {
  de,
  it,
  en,
}[currentLanguage];

export default i18n;
