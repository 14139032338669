export const initPopup = () => {
  const dialog = document.querySelector<HTMLDialogElement>(".js-popup");
  const id = dialog?.dataset["id"];
  const expired = dialog?.dataset["isExpired"];

  if (
    !document.cookie.includes(`eventPopupIdClosed=${id}`) &&
    expired === "false"
  ) {
    const closeBtn = document.querySelectorAll<HTMLElement>(".js-popup-close");

    dialog?.showModal();

    closeBtn.forEach((btn) => {
      btn.addEventListener("click", () => {
        dialog?.close();
        document.cookie = `eventPopupIdClosed=${id}; Secure;SameSite=strict`;
      });
    });
  }
};
