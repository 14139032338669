import classNames from "classnames";
import { useCombobox } from "downshift";
import { useId } from "react";
import { Fragment } from "react/jsx-runtime";
import CheckboxBlank from "../../../img/checkbox-blank.svg?react";
import Checkbox from "../../../img/checkbox.svg?react";
import CloseMap from "../../../img/close-map.svg?react";
import { RegionName } from "../../@types";
import { Region } from "../../http/api";
import i18n from "../../i18n";
import useIsFocused from "../hooks/useIsFocused";
import Icon from "../ui/Icon";
import styles from "./Search.module.css";

interface Props {
  regions: Region[];
  selectedItems: Region[];
  onUpdate: (regions: Region[]) => void;
}

const RegionField = ({ regions, selectedItems, onUpdate }: Props) => {
  const { ref, isFocused, setFocused } = useIsFocused<HTMLDivElement>(false);
  const inputId = useId();

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: regions,
    itemToString: (item) => item?.name ?? "",
    selectedItem: null,
    onSelectedItemChange: ({ selectedItem }) => {
      onUpdate(
        selectedItems.map(({ id }) => id).includes(selectedItem.id)
          ? selectedItems.filter(({ id }) => selectedItem.id !== id)
          : [...selectedItems, selectedItem],
      );
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: state.highlightedIndex,
            inputValue: "",
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: "",
          };
        default:
          return changes;
      }
    },
  });

  return (
    <div ref={ref} className={styles.inputContainer}>
      <label htmlFor={inputId} className={styles.fieldLabel}>
        {i18n.searchBar.REGION}
      </label>
      <div className={styles.inputField}>
        <input
          {...getInputProps({
            onClick: () => setFocused(true),
          })}
          placeholder={
            selectedItems.length
              ? `${selectedItems.length} ${i18n.searchBar.SELECTED}`
              : i18n.searchBar.REGION
          }
          className={styles.input}
          readOnly={true}
          id={inputId}
        />
        {!!selectedItems.length && isFocused && (
          <button
            type="button"
            className={styles.closeBtn}
            onClick={() => onUpdate([])}
          >
            <Icon glyph={CloseMap} className={styles.closeIcon} />
          </button>
        )}
      </div>
      <div className={styles.regionOverlay} {...getMenuProps()}>
        {isOpen && (
          <ul className={styles.searchMenuOpen}>
            <li className={styles.itemCategory}>{i18n.searchBar.REGION}</li>
            {regions?.map((item, index) => (
              <Fragment key={index}>
                <li
                  {...getItemProps?.({
                    item,
                    index,
                  })}
                  className={classNames(styles.item, {
                    [styles.itemActive]: highlightedIndex === index,
                  })}
                >
                  <Icon
                    glyph={
                      selectedItems.map(({ id }) => id).includes(item.id)
                        ? Checkbox
                        : CheckboxBlank
                    }
                  />
                  <span>{i18n.regions[item.name as RegionName]}</span>
                </li>
              </Fragment>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default RegionField;
